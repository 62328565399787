import React, { Component } from 'react';
import ProjectsPage from '../sections/ProjectsPage'

class Projects extends Component {

    render() {
        return (
            <div>
                <ProjectsPage />
            </div>
        )
    }

}
export default Projects