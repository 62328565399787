import React, { Component } from 'react';
import { 
    Container, 
    MDBJumbotron,
    Row, 
    Col, 
 } from 'mdbreact';

class CareersPage extends Component {
    render() {

        return (
            <Container>
                <MDBJumbotron style={{ marginTop: "1rem" }}>
                    <h2 className="card-title"> Careers </h2>
                    <p>
                    We are always looking for new talent to join us at Cascade.
                    </p>
                    <p>
                    Send your info & résumé to <a href="malto:info@cascade.build">info@cascade.build</a> and we'll take it from there.
                    </p>

                    <hr className="my-2" />

                </MDBJumbotron>
            </Container>
        );
    };
}

export default CareersPage;