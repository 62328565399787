import React, { Component } from 'react';

import ContactPage from '../sections/ContactPage'

class Contact extends Component {

    render() {
        return (
            <div>
                <ContactPage />
            </div>
        )
    }

}
export default Contact